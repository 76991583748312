import AdFormService from "../../../common/ts/services/AdFormService";
import PageData from "../../../common/ts/PageData";
import {Section} from "../../../common/ts/services/Section";
import {EventBus} from "../../../common/ts/events/EventBus";
import {ProjectUnitButtonClickedEvent, RefreshSwipeComponentEvent} from "../../../common/ts/events/Events";
import HipflatProjectUnitsService from "./HipflatProjectUnitsService";
import {HipflatPagination} from "./HipflatPagination";
import {WlSubmit} from "../../../common/ts/webcomponents/events/WlSubmit";

export default class ProjectUnits {
    private readonly pageData: PageData;
    private adFormService: AdFormService = new AdFormService();
    private readonly eventBus: EventBus;
    private paginationList: HipflatPagination[];
    private sorting: string = '';

    constructor(pageData: PageData) {
        this.pageData = pageData;
        this.eventBus = EventBus.getInstance();
        document.querySelectorAll(`.js-units-tab-radio`).forEach(radioButton => {
            if ((radioButton as HTMLInputElement).checked) {
                this.sendMetrics(radioButton);
            }
            radioButton.addEventListener('input', () => {
                this.eventBus.emit(new RefreshSwipeComponentEvent());
                this.sendMetrics(radioButton);
            });
        });
        this.subscribeClickButtons(pageData, document.querySelectorAll('.js-unit-element'));
        document.querySelectorAll("input[name^='unitsBedrooms-']").forEach(inputElement => {
            inputElement.addEventListener('click', () => {
                this.renderUnits(1);
            });
        });
        this.paginationList = Array.from(document.querySelectorAll(".js-units-pagination")).map(paginationContainerElement => {
            const paginationContainer = paginationContainerElement as HTMLElement;
            return new HipflatPagination(this.renderUnitsPagination(paginationContainer).bind(this), paginationContainer);
        });

        this.subscribeDropdownSortingChange();
    }

    private renderUnitsPagination(paginationContainer: HTMLElement) {
        return (page: number) => {
            this.renderUnits(page);
            this.pageScrollToSectionStart(paginationContainer);
        }
    }

    private pageScrollToSectionStart(paginationContainer: Element) {
        const sectionElement = paginationContainer.closest(".js-availableUnits") as HTMLElement | null;
        const sectionPosition = sectionElement?.offsetTop ?? window.scrollY;
        window.scrollTo(0, sectionPosition);
    }

    private subscribeClickButtons(pageData: PageData, contactButtons: NodeListOf<Element>) {
        const section: Section = Section.SECTION_ADPAGE_PROJECTS_MODEL_CONTACT;
        contactButtons.forEach(unitElement => {
            const propertyAdId: string = unitElement.getAttribute('data-unit-id')!;
            const showOperationSelector = unitElement.getAttribute("data-unit-show-operation-selector")! == "true";
            const showRentDatePicker = unitElement.getAttribute("data-unit-show-rent-date-picker")! == "true";
            const isRent = unitElement.getAttribute("data-unit-operation")! == "RENT";
            unitElement
                .querySelector('.js-unit-enquire-button')!
                .addEventListener('click', (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.eventBus.emit(new ProjectUnitButtonClickedEvent({
                        section: section,
                        propertyAdId: propertyAdId,
                        pageViewId: pageData.pageViewId,
                        step: 2,
                        pageViewType: pageData.pageViewType,
                        showOperationSelector: showOperationSelector,
                        showRentDatePicker: showRentDatePicker,
                        isRent: isRent,
                    }));
                });
        });
    }

    static init = (pageData: PageData) => {
        return new ProjectUnits(pageData);
    };

    private sendImpressionForListing(operation: string) {
        let propertyAdIds: string[] = []
        const units = Array.from(document.querySelectorAll(`[data-unit-operation="${operation}"]`));
        units.forEach(unit => {
            propertyAdIds.push(unit.getAttribute('data-unit-id')!);
        });
        if (propertyAdIds.length > 0) {
            this.adFormService.listingImpression({
                pageViewId: this.pageData.pageViewId,
                propertyAdIds: propertyAdIds,
                section: operation == "SALE" ? Section.SECTION_SHOWCASE_ADS_SALE : Section.SECTION_SHOWCASE_ADS_RENT,
            });
        }
    }

    private sendMetrics(radioButton: Element) {
        if (radioButton.getAttribute("id") == "tab-sale") {
            let operation = 1;
            this.sendImpressionForListing("SALE");
            // this.sendApplies(operation);
        } else {
           this.sendImpressionForListing("RENT");
            // this.sendApplies(operation);
        }
    }

    private sendApplyImpression(id: string | null, operation: number) {
        let multiEnquiryListingPageData = Object.create(this.pageData);
        multiEnquiryListingPageData.id = id;
        this.adFormService.applyImpressionProjectListings(
            multiEnquiryListingPageData,
            operation,
            1
        );
    }

    private sendApplies(operation: number) {
        if (operation == 1) {
            const units = Array.from(document.querySelectorAll('[data-unit-operation="SALE"]')).slice(0, 6);
            units.forEach(unit => {
                let propertyAdId = unit.getAttribute('data-unit-id')
                this.sendApplyImpression(propertyAdId, operation)
            });
        } else {
            const units = Array.from(document.querySelectorAll('[data-unit-operation="RENT"]')).slice(0, 6);
            units.forEach(unit => {
                let propertyAdId = unit.getAttribute('data-unit-id')
                this.sendApplyImpression(propertyAdId, operation)
            });
        }
    }

    private renderUnits(page: number) {
        const operationTypeChecked = document.querySelector(".js-units-tab-radio:checked") as HTMLInputElement;
        const unitsDiv = document.querySelector(".units." + operationTypeChecked.value) as HTMLElement;
        const bedrooms = unitsDiv?.querySelector("input[name^='unitsBedrooms-']:checked") as HTMLInputElement;
        const containerElement = document.querySelector(".js-availableUnits") as HTMLElement;
        const projectId = containerElement.getAttribute("data-id");
        if (projectId != null) {
            let unitsContainer = this.getUnitsContainer(unitsDiv);
            let newUnitsSnippetTable = this.isNewUnitsSnippet(unitsDiv);
            if (unitsContainer){
                this.showLoadingUnits(unitsContainer);
                this.loadUnits(unitsContainer,
                    this.getPaginationContainer(unitsDiv),
                    projectId,
                    operationTypeChecked.getAttribute("data-slug")!!,
                    bedrooms.value,
                    operationTypeChecked,
                    page,
                    newUnitsSnippetTable,
                    this.sorting
                );
            }
        }
    }

    private getPaginationContainer(unitsDiv: HTMLElement) {
        return unitsDiv.querySelector(".js-units-pagination") as HTMLElement | null;
    }

    private isNewUnitsSnippet(unitsDiv: HTMLElement) {
        const unitsList = unitsDiv.querySelector(".js-units-list") as HTMLElement;
        let newUnitsSnippetTable = false;
        if (unitsList) {
            newUnitsSnippetTable = true;
        }
        return newUnitsSnippetTable;
    }

    private getUnitsContainer(unitsDiv: HTMLElement) {
        const swiperContentDiv = unitsDiv.querySelector(".js-wl-swipe-elements") as HTMLElement;
        const unitsList = unitsDiv.querySelector(".js-units-list") as HTMLElement;
        let unitsContainer;
        if (swiperContentDiv) {
            unitsContainer = swiperContentDiv;
        } else if (unitsList) {
            unitsContainer = unitsList;
        }
        return unitsContainer;
    }

    private loadUnits = async (unitsContainer: HTMLElement,
                               paginationContainer: HTMLElement | null,
                               projectId: string,
                               operationType: string,
                               bedrooms: string,
                               operationTypeChecked: HTMLInputElement,
                               page: number,
                               newUnitsSnippetTable: boolean,
                               sorting: string) => {
        const response = await new HipflatProjectUnitsService().getProjectUnits(this.pageData.pageViewId, projectId, operationType, bedrooms, page, newUnitsSnippetTable, sorting);
        const projectUnitsResponse = JSON.parse(response) as ProjectUnitsResponse;
        this.refreshPagination(paginationContainer, projectUnitsResponse);
        this.refreshUnits(unitsContainer, projectUnitsResponse, operationTypeChecked);
    }

    private refreshUnits(unitsContainer: HTMLElement, projectUnitsResponse: ProjectUnitsResponse, operationTypeChecked: HTMLInputElement) {
        unitsContainer.innerHTML = projectUnitsResponse.projectUnitList;
        this.subscribeClickButtons(this.pageData, unitsContainer.querySelectorAll(".js-unit-element"));
        this.eventBus.emit(new RefreshSwipeComponentEvent());
        this.sendMetrics(operationTypeChecked)
        unitsContainer.scrollLeft = 0;
        this.hideLoadingUnits(unitsContainer);
    }

    private showLoadingUnits(unitsContainer: HTMLElement) {
        unitsContainer.style.opacity = "0.3";
    }

    private hideLoadingUnits(unitsContainer: HTMLElement) {
        unitsContainer.style.opacity = "1";
    }

    private refreshPagination(paginationContainer: HTMLElement | null, projectUnitsResponse: ProjectUnitsResponse) {
        if (paginationContainer) {
            paginationContainer.innerHTML = projectUnitsResponse.pagination;
            // this.subscribeClickPaginationFor(paginationContainer);
            this.paginationList.forEach(value => {
                value.refresh();
            });
        }
    }

    private subscribeDropdownSortingChange() {
        document.querySelectorAll(".project-units-sorting").forEach(dropdown => {
            dropdown.addEventListener(WlSubmit.type, () => {
                this.sorting = (dropdown as HTMLInputElement).value;
                this.renderUnits(1);
            });
        });
    }
}

interface ProjectUnitsResponse {
    projectUnitList: string;
    pagination: string;
}