import PageData from "../../common/ts/PageData";
import AdFormService, {FormExtraData} from "../../common/ts/services/AdFormService";
import ContactForm from "../../common/ts/forms/ContactForm";
import UserContactDataStorageService, {
    UserContactDataType
} from "../../common/ts/services/UserContactDataStorageService";
import {Section} from "../../common/ts/services/Section";
import {EventBus} from "../../common/ts/events/EventBus";
import {
    AgencyPhoneButtonClickedEvent,
    AppliedFormEvent,
    ChatButtonClickedEvent,
    FullPageModalCloseEvent,
    FullPageModalOpenEvent,
    PhoneTrackingModalOpenEvent,
    PhoneContactSentEvent
} from "../../common/ts/events/Events";
import {InternationalPhoneInput} from "../../common/ts/forms/validator/phone/InternationalPhoneInput";
import {Device} from "../../common/ts/types/Device";
import LeadTrackingService from "../../common/ts/services/LeadTrackingService";
import UserData from "../../common/ts/UserData";
import {PhoneTrackingResponse} from "../../common/ts/types/PhoneTrackingResponse";
import {getChatSectionIdsMap, getChatSectionsMap} from "../../common/ts/ChatSections";
import Logging from "../../common/ts/Logging";
import HipflatFormTabs from "../../common/ts/forms/HipflatFormTabs";

const preferredPhoneCountries = ["th", "gb", "us"];

export const hipflatFormIdSelector: string = '#adform-contact-form';

const PHONE_MODAL_ID: string = 'phone-modal';

export default class HipflatForm {
    private adFormService: AdFormService;
    private contactForm: ContactForm;
    private userContactDataStorageService: UserContactDataStorageService;
    private readonly pageData: PageData;
    private readonly nameInput: HTMLInputElement;
    private readonly emailInput: HTMLInputElement;
    private readonly phoneInput: HTMLInputElement;
    private readonly internationalPhoneInput: InternationalPhoneInput;
    private readonly form: HTMLElement | null
    private readonly phoneTracking: PhoneTracking
    section: Section | null = null;
    listingId: string;

    private readonly eventBus: EventBus = EventBus.getInstance();
    private readonly chatSections: ChatSections;
    private hideMessageField?: boolean;

    static init(pageData: PageData) {
        document.querySelectorAll(hipflatFormIdSelector).forEach((formContainer) => {
            new HipflatForm(pageData, formContainer as HTMLElement);
        });
    }

    static initForm(form: HTMLElement, pageData: PageData){
        return new HipflatForm(pageData, form);
    }

    private constructor(pageData: PageData, formContainer: HTMLElement) {
        this.form = formContainer
        this.pageData = pageData;
        this.adFormService = new AdFormService();

        this.userContactDataStorageService = new UserContactDataStorageService();
        this.nameInput = formContainer.querySelector('#form-name')! as HTMLInputElement;
        this.emailInput = formContainer.querySelector('#form-email')! as HTMLInputElement;
        this.phoneInput = formContainer.querySelector('#form-phone')! as HTMLInputElement;
        this.internationalPhoneInput = InternationalPhoneInput.init(this.phoneInput,
            this.pageData.country.toLowerCase(),
            preferredPhoneCountries
        );
        const message = formContainer.querySelector('#form-message')! as HTMLTextAreaElement;
        const submitButton = formContainer.querySelector('#request-info-send-button')! as HTMLButtonElement;

        HipflatFormTabs.init(this.form, pageData, this.onTabChanged.bind(this), this.trackImpressionSchedule.bind(this));

        this.contactForm = new ContactForm({
            pageData: pageData,
            formContainer: formContainer,
            nameElement: this.nameInput,
            emailElement: this.emailInput,
            phoneElement: this.phoneInput,
            messageElement: message,
            submitElement: submitButton,
            onSubmit: this.onContactFormSubmit,
            internationalPhoneInput: this.internationalPhoneInput,
        });
        this.section = this.contactForm?.getSection()
        this.listingId = this.pageData.id;
        this.userContactDataStorageService.fillInputsWithStoredValues(
            {input: this.emailInput, type: UserContactDataType.EMAIL},
            {input: this.nameInput, type: UserContactDataType.NAME},
            {input: this.phoneInput, type: UserContactDataType.PHONE},
        );
        this.internationalPhoneInput.setNumber(this.phoneInput.value);
        const seePhoneButton = formContainer.querySelector('.js-see-phone-button');
        seePhoneButton?.addEventListener('click', () => {
            const section: Section = seePhoneButton.getAttribute('data-section') as Section || Section.AGENCY_PHONE_NUMBER_DISPLAYED;
            this.eventBus.emit(new AgencyPhoneButtonClickedEvent({
                section: section,
                pageViewId: this.pageData.pageViewId,
                propertyAdId: this.pageData.id,
                pageViewType: this.pageData.pageViewType,
            }));
        });
        this.eventBus.subscribe(AppliedFormEvent.TYPE, (event) => {
            const {name, email, phone} = (event as AppliedFormEvent).payload;
            this.nameInput.value = name;
            this.emailInput.value = email;
            this.phoneInput.value = phone;
            this.internationalPhoneInput.setNumber(phone);
            this.phoneInput.dispatchEvent(new Event('keyup'));
        });

        this.phoneTracking = new PhoneTracking(
            EventBus.getInstance(),
            this.pageData,
            this.adFormService,
            PHONE_MODAL_ID,
            this.contactForm
        );

        this.chatSections = new ChatSections(
            new LeadTrackingService(this.pageData),
            pageData,
            new LastChatButtonClicked(),
            this.adFormService,
            this.contactForm,
        );

    }

    public reset() {
        this.form?.classList.remove('submitted');
    }

    public showHideMessageField(hideMessageField: boolean) {
        this.hideMessageField = hideMessageField;
        if (this.form) {
            if (hideMessageField) {
                this.form.classList.add('message-hidden');
            } else {
                this.form.classList.remove('message-hidden');
            }
        }
    }

    private onTabChanged(section: Section) {
        this.section = section;
    }

    private trackImpressionSchedule() {
        this.adFormService.sendApplyImpression({
            pageViewId: this.pageData.pageViewId,
            propertyAdId: this.pageData.id,
            section: this.section!!,
            step: 3,
            isProject: false,
        });
    }

    private onContactFormSubmit = async () => {
        if (this.getCompletePhone()) {
            this.phoneInput.value = this.getCompletePhone()
        }
        const section = this.section ?? '';
        if (this.isProjectForm(section)) {
            await this.applyProject();
        } else if (
            section === Section.AGENCY_PHONE_NUMBER_DISPLAYED ||
            section === Section.SECTION_ADPAGE_IMAGE_CAROUSEL_PHONE ||
            section === Section.SECTION_ADPAGE_DESCRIPTION_VIEW_PHONE
        ) {
            await this.phoneTracking.manage(section, this.getUserData());
        } else if (this.isAChatSection(section)) {
            this.chatSections.manage(section, this.getUserData(), this.hideMessageField);
        } else {
            await this.applyListing();
        }
        this.userContactDataStorageService.storeUserInputValue(this.nameInput, UserContactDataType.NAME);
        this.userContactDataStorageService.storeUserInputValue(this.emailInput, UserContactDataType.EMAIL);
        this.userContactDataStorageService.storeUserInputValue(this.phoneInput, UserContactDataType.PHONE);
        this.eventBus.emit(new AppliedFormEvent({
            name: this.nameInput.value,
            email: this.emailInput.value,
            phone: this.phoneInput.value,
        }));
    }

    private isProjectForm(section: Section | string) {
        return this.contactForm.isProjectForm || (
            section == Section.SECTION_ADPAGE_STICKY_BOTTOM_CONTACT_FORM &&
            this.pageData.pageViewType != "DETAIL_PAGE")
    }


    private applyProject() {
        return this.adFormService.applyProject({
            pageViewId: this.pageData.pageViewId,
            projectId: this.pageData.id,
            userName: this.contactForm.nameValue(),
            userEmail: this.contactForm.emailValue(),
            userPhone: this.getCompletePhone(),
            message: this.contactForm.messageValue(),
            section: this.contactForm.getSection() ?? '',
            operationType: this.contactForm.selectedOperationType(),
            rentStartDate: this.contactForm.rentStartDate(),
            rentEndDate: this.contactForm.rentEndDate(),
        });
    }

    private applyListing() {
        let section = this.section;
        let scheduleDate = null;
        let scheduleTime = null;

        if (section && this.isScheduleApply(section)) {
            scheduleDate = this.contactForm.scheduleDate();
            scheduleTime = this.contactForm.scheduleTime();
        }
        return this.adFormService.leadContactApply(
            {
                pageViewId: this.pageData.pageViewId,
                propertyAdId: this.listingId!!,
                userName: this.contactForm.nameValue(),
                userEmail: this.contactForm.emailValue(),
                userPhone: this.getCompletePhone(),
                message: this.contactForm.messageValue(),
                section: section ?? null,
                operationType: this.contactForm.selectedOperationType(),
                rentStartDate: this.contactForm.rentStartDate(),
                rentEndDate: this.contactForm.rentEndDate(),
                scheduleDate: scheduleDate,
                scheduleTime: scheduleTime,
            });
    }

    private isScheduleApply(section: Section) {
        return section == Section.SECTION_ADPAGE_TAB_VISIT ||
            section == Section.SECTION_ADPAGE_IMAGE_CAROUSEL_VISIT ||
            section == Section.SECTION_ADPAGE_STICKY_BOTTOM_VISIT;
    }

    private getCompletePhone() {
        return this.internationalPhoneInput.getNumber();
    }

    private getUserData = (): UserData => {
        const userData: UserData = {};
        if (this.emailInput && this.emailInput.value) userData.email = this.emailInput.value;
        if (this.nameInput && this.nameInput.value) userData.name = this.nameInput.value;
        if (this.phoneInput && this.phoneInput.value) userData.phone = this.getCompletePhone();
        return userData;
    };

    private isAChatSection(applySection: string) {
        let result = false
        getChatSectionsMap().forEach((obj, key) => {
            if (applySection == obj.section.toString()) {
                result = true;
            }
        });
        return result;
    }

    managePhoneTracking() {
        this.phoneTracking.manageWithoutMetrics(this.section!, this.getUserData())
    }
};

class LastChatButtonClicked {
    private static instance: LastChatButtonClicked;
    private htmlElement: HTMLElement | null = null;

    constructor() {
        const eventBus = EventBus.getInstance();
        eventBus.subscribe(ChatButtonClickedEvent.TYPE, (event) => {
            const payload = (event as ChatButtonClickedEvent).payload
            this.htmlElement = payload.htmlButtonClicked
        });
    }

    get() {
        return this.htmlElement
    }

    public static getInstance(): LastChatButtonClicked {
        if (!LastChatButtonClicked.instance) {
            LastChatButtonClicked.instance = new LastChatButtonClicked();
        }

        return LastChatButtonClicked.instance;
    }
}


class ChatSections {
    private readonly pageData: PageData
    private readonly lastChatButtonClicked: LastChatButtonClicked
    private readonly whatsapp: Whatsapp
    private readonly adFormService: AdFormService
    private contactForm: ContactForm;

    constructor(leadTracking: LeadTrackingService,
                pageData: PageData,
                lastChatButtonClicked: LastChatButtonClicked,
                adFormService: AdFormService,
                contactForm: ContactForm,
    ) {
        this.pageData = pageData
        this.lastChatButtonClicked = lastChatButtonClicked
        this.adFormService = adFormService
        this.contactForm = contactForm;
        this.whatsapp = new Whatsapp(leadTracking, pageData, this.lastChatButtonClicked)

        this.enableOpenNewWindow();
    }

    private open(section: Section | string) {
        if (this.isAWhatsappSection(section)) {
            this.whatsapp.open(section)
        } else if (this.isALineSection(section) || this.isAFacebookSection(section)) {
            this.openChatTab()
        }
    }

    manage(section: Section | string, userData: UserData, hideMessageField?: boolean) {
        const formExtraData: FormExtraData = {
            rentStartDate: this.contactForm.rentStartDate(),
            rentEndDate: this.contactForm.rentEndDate(),
        };
        if (hideMessageField === false) {
            formExtraData.message = this.contactForm.messageValue();
        }
        this.adFormService.applyChatOptionDisplay(
            this.pageData,
            section,
            userData,
            formExtraData
        );
        this.open(section);
    }

    private isAWhatsappSection(applySection: string) {
        return this.isAChatSectionFor(applySection, 'Whatsapp');
    }

    private isALineSection(applySection: string) {
        return this.isAChatSectionFor(applySection, 'Line');
    }

    private isAFacebookSection(applySection: string) {
        return this.isAChatSectionFor(applySection, 'Messenger');
    }

    private isAChatSectionFor(applySection: string, chatType: string) {
        let result = false
        getChatSectionsMap().forEach((obj, key) => {
            if (applySection == obj.section.toString() && obj.name == chatType) {
                result = true;
            }
        });
        return result;
    }

    private openChatTab() {
        let chatButtonClicked = this.lastChatButtonClicked.get()
        if (chatButtonClicked != null) {
            const value = chatButtonClicked.getAttribute("value")
            if (value) {
                const win = window.open(value, '_blank');
                if (win) win.focus();
            }
        }
    }

    private enableOpenNewWindow() {
        //This is needed for e2e whatsapp test
        if (!document.getElementById("window-opener")) {
            const anchor = document.createElement('a');
            anchor.setAttribute("data-test", "window-opener");
            anchor.target = '_blank';
            anchor.id = 'window-opener';
            anchor.style.display = 'none';
            document.body.appendChild(anchor);
        }
    }
}

class Whatsapp {
    private leadTracking: LeadTrackingService
    private pageData: PageData
    private lastChatButtonClicked: LastChatButtonClicked

    constructor(leadTracking: LeadTrackingService, pageData: PageData, lastChatButtonClicked: LastChatButtonClicked) {
        this.leadTracking = leadTracking;
        this.pageData = pageData
        this.lastChatButtonClicked = lastChatButtonClicked
    }

    open(section: string) {
        const sectionId = getChatSectionIdsMap().get(section)
        if (sectionId) {
            this.leadTracking.getWhatsappData(sectionId).then((chatResponse) => {
                if (chatResponse.status === 200) {
                    this.openWhatsappChatTracking(chatResponse);
                } else {
                    this.openWhatsappWithoutChatTracking();
                }
            });
        }
    }

    private openWhatsappChatTracking(chatResponse: Response) {
        chatResponse.json().then((chatResponseJson) => {
            const {url} = chatResponseJson.data;
            this.openWhatsappByDevice(url);
        });
    }

    private openWhatsappWithoutChatTracking() {
        let chatButtonClicked = this.lastChatButtonClicked.get()
        if (chatButtonClicked != null) {
            const value = chatButtonClicked.getAttribute("value")
            if (value) {
                this.openWhatsappByDevice(value)
            }
        }
    }

    private openWhatsappByDevice(url: string) {
        if (this.pageData.device === 'DESKTOP') {
            const chatTargetAnchor = document.getElementById("window-opener") as HTMLAnchorElement;
            chatTargetAnchor.href = url;
            chatTargetAnchor.click();
        } else {
            document.location.href = url;
        }
    }

}

class PhoneTracking {
    private readonly eventBus: EventBus
    private readonly pageData: PageData
    private readonly adFormService: AdFormService;
    private readonly phoneModalId: string;
    private response: PhoneTrackingResponse | null = null;
    private contactForm: ContactForm;

    constructor(eventBus: EventBus,
                pageData: PageData,
                adFormService: AdFormService,
                phoneModalId: string,
                contactForm: ContactForm
    ) {
        this.eventBus = eventBus
        this.pageData = pageData
        this.adFormService = adFormService
        this.phoneModalId = phoneModalId
        this.contactForm = contactForm;
    }

    async manage(section: Section, userData: UserData) {
        this.eventBus.emit(new FullPageModalCloseEvent({id: this.phoneModalId}));
        this.sendApplyEvent(section, userData);
        this.response = this.pageData.device === Device.MOBILE
            ? await new LeadTrackingService(this.pageData).getPhoneAutomaticData(this.pageData.id)
            : await new LeadTrackingService(this.pageData).getPhoneManualData(this.pageData.id);

        this.eventBus.emit(new PhoneContactSentEvent())
        return this.manageWithoutMetrics(section, userData);
    }

    manageWithoutMetrics(section: Section, userData: UserData) {
        if (!this.response?.data) {
            return this.showAgencyPhoneInModal(section);
        }
        return this.showSherpasTrackingPhoneInModal(this.response, section, userData);
    }

    private sendApplyEvent(section: Section, userData: UserData) {
        const formExtraData: FormExtraData = {
            rentStartDate: this.contactForm.rentStartDate(),
            rentEndDate: this.contactForm.rentEndDate(),
            message: this.contactForm.messageValue(),
        };
        this.adFormService
            .applyEvent(this.pageData, section, userData, formExtraData)
            .catch((error) => Logging.error(error));
    }

    private showAgencyPhoneInModal = (section: Section) => {
        const phoneLinkElements = document.getElementsByClassName("js-agency-phone-number");
        const requestedPhone = atob(this.pageData.agencyPhone);
        if (requestedPhone && phoneLinkElements.length > 0) {
            const agencyPhoneElement = phoneLinkElements[0] as HTMLAnchorElement;
            agencyPhoneElement.setAttribute('href', `tel:${requestedPhone}`);
            agencyPhoneElement.text = requestedPhone
        }
        this.eventBus.emit(new FullPageModalOpenEvent({id: 'agency-phone-modal'}));
    }

    private showSherpasTrackingPhoneInModal = (json: PhoneTrackingResponse, section: Section, userData: UserData) => {
        if (!json.data) return;
        this.eventBus.emit(new PhoneTrackingModalOpenEvent({
            section: section,
            pageViewId: this.pageData.pageViewId,
            propertyAdId: this.pageData.id,
            requestedPhone: json.data.contactNumber,
            requestedPhoneExtension: json.data.advertiserCode,
            userData: userData,
        }));
    };
}
